import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import MyImage from 'components/MyImage'
import { graphql, Link, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { BlogMainMenuQuery } from 'types/graphql'

/**
 * Blog Index #bQMvQ#
 **/
export default () => {
  const query = useStaticQuery<BlogMainMenuQuery>(graphql`
      query BlogMainMenu {
          allMdx(filter: {fileAbsolutePath: {glob: "**/blog/*/*.mdx"}, # #Ps8bA#
              frontmatter: {disabled: {ne: true}}},
              sort: {fields: [frontmatter___date], order: DESC }) {
              nodes {
                  slug
                  frontmatter {
                      title
                      date
                      dateStr: date(formatString: "ll")
                      intro
                      image
                  }
              }
          }
      }
  `)
  return <div>
    <Header fixed />
    <main className="py-24 container-w">
      {query.allMdx.nodes.map(blogPost => <div
          key={blogPost.slug}
          className="sm:grid grid-cols-6 gap-4 mb-8">{/*#Fwza6#*/}
          {/*<article className="xl:grid xl:grid-cols-4 xl:space-y-0 xl:items-baseline">*/}
          <dl>
            <dt className="sr-only">Published on</dt>
            <dd className="whitespace-nowrap text-gray-400
            prose prose-sm lg:prose-lg xl:prose-xl
            mt-0.5 sm:mt-1
            text-right
            ">
              <time dateTime={blogPost.frontmatter!.date}>{blogPost.frontmatter!.dateStr}</time>
            </dd>
          </dl>
          <article className="space-y-5 col-span-5  prose prose-sm sm:prose lg:prose-lg xl:prose-xl ">
            <div className="space-y-6">
              <h3 className="" style={{ marginTop: 0 }}>
                <Link to={blogPost.slug!} style={{ textDecoration: 'inherit' }}>{blogPost.frontmatter!.title}</Link>
              </h3>
              {blogPost.frontmatter!.image && <MyImage src={blogPost.frontmatter!.image} alt="XCode settings" />}
              <div className="max-w-none">
                <p dangerouslySetInnerHTML={{ __html: blogPost.frontmatter!.intro ?? '' }} />
              </div>
            </div>
            <div className="text-base leading-6 font-medium">
              <Link to={blogPost.slug!} style={{ textDecoration: 'inherit' }}
                    className="text-teal-500 hover:text-black">
                Read more</Link>&nbsp;→
            </div>
          </article>
        </div>
      )}

    </main>
    <Footer />
  </div>
};
